var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-tabs",
        {
          staticClass: "rounded-lg",
          attrs: {
            "background-color": "grey lighten-4",
            "slider-size": "0",
            grow: "",
          },
          model: {
            value: _vm.tabs,
            callback: function ($$v) {
              _vm.tabs = $$v
            },
            expression: "tabs",
          },
        },
        [
          _c(
            "v-tab",
            { attrs: { "active-class": "primary white--text rounded-lg" } },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    inline: "",
                    color: "primary lighten-1",
                    content: _vm.allCount,
                    value: _vm.allCount,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("All")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-tab",
            { attrs: { "active-class": "primary white--text rounded-lg" } },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    inline: "",
                    color: "primary lighten-1",
                    content: _vm.doneCount,
                    value: _vm.doneCount,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Done")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tabs,
            callback: function ($$v) {
              _vm.tabs = $$v
            },
            expression: "tabs",
          },
        },
        [
          _c(
            "v-tab-item",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _vm.allItems && _vm.allItems.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.allItems, function (exam, index) {
                          return _c(
                            "v-card",
                            {
                              key: index,
                              staticClass: "my-2",
                              attrs: { flat: "", hover: "", ripple: "" },
                            },
                            [
                              _c(
                                "b-list-group",
                                [
                                  _c(
                                    "b-list-group-item",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickExam(
                                            exam,
                                            false
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          attrs: {
                                            color: "primary lighten-5",
                                            size: "48",
                                          },
                                        },
                                        [
                                          exam.profilePicture
                                            ? _c("v-img", {
                                                attrs: {
                                                  "lazy-src": require("@/assets/images/profile-circle.png"),
                                                  src: exam.profilePicture,
                                                },
                                              })
                                            : _c("v-img", {
                                                attrs: {
                                                  src: require("@/assets/images/profile.jpg"),
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column ms-2 me-0 align-items-start justify-content-center w-100",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex ms-1 me-0 align-items-center justify-content-between w-100",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "f14" },
                                                [
                                                  _vm._v(
                                                    _vm._s(exam.firstName) +
                                                      " " +
                                                      _vm._s(exam.lastName)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "f12 grey--text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("moment")(
                                                        exam.createDate,
                                                        "from",
                                                        "now"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "f12 ms-1 me-0 grey--text",
                                            },
                                            [_vm._v(_vm._s(exam.examName))]
                                          ),
                                          exam.status == "ANSWERED"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "f13 ms-1 me-0 green--text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("Done (View)")
                                                    )
                                                  ),
                                                ]
                                              )
                                            : exam.status == "PAID"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "f13 ms-1 me-0 green--text",
                                                },
                                                [_vm._v(_vm._s(_vm.$t("Paid")))]
                                              )
                                            : exam.status == "PAYMENT_PENDING"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "f13 ms-1 me-0 red--text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Not paid"))
                                                  ),
                                                ]
                                              )
                                            : exam.status == "FREE"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "f13 ms-1 me-0 primary--text",
                                                },
                                                [_vm._v(_vm._s(_vm.$t("Free")))]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "f13 ms-1 me-0 grey--text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("Unknown"))
                                                  ),
                                                ]
                                              ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c(
                        "span",
                        { staticClass: "text-center f16 d-block mt-8" },
                        [_vm._v(" " + _vm._s(_vm.$t("No results found")) + " ")]
                      ),
                  _c(
                    "infinite-loading",
                    {
                      attrs: { identifier: _vm.tabs },
                      on: { infinite: _vm.infiniteHandlerAll },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "spinner" }, slot: "spinner" },
                        [_c("v-icon", [_vm._v("mdi-selection-ellipse spin")])],
                        1
                      ),
                      _c(
                        "div",
                        { attrs: { slot: "no-more" }, slot: "no-more" },
                        [_c("v-icon", [_vm._v("mdi-access-point-off")])],
                        1
                      ),
                      _c(
                        "div",
                        { attrs: { slot: "no-results" }, slot: "no-results" },
                        [_c("v-icon", [_vm._v("mdi-access-point-off")])],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _vm.doneItems && _vm.doneItems.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.doneItems, function (exam, index) {
                          return _c(
                            "v-card",
                            {
                              key: index,
                              staticClass: "my-2",
                              attrs: { flat: "", hover: "", ripple: "" },
                            },
                            [
                              _c(
                                "b-list-group",
                                [
                                  _c(
                                    "b-list-group-item",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickExam(exam, true)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          attrs: {
                                            color: "primary lighten-5",
                                            size: "48",
                                          },
                                        },
                                        [
                                          exam.profilePicture
                                            ? _c("v-img", {
                                                attrs: {
                                                  "lazy-src": require("@/assets/images/profile-circle.png"),
                                                  src: exam.profilePicture,
                                                },
                                              })
                                            : _c("v-img", {
                                                attrs: {
                                                  src: require("@/assets/images/profile.jpg"),
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column ms-2 me-0 align-items-start justify-content-center w-100",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex ms-1 me-0 align-items-center justify-content-between w-100",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "f14" },
                                                [
                                                  _vm._v(
                                                    _vm._s(exam.firstName) +
                                                      " " +
                                                      _vm._s(exam.lastName)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "f12 grey--text",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("moment")(
                                                        exam.createDate,
                                                        "from",
                                                        "now"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "f12 ms-1 me-0 grey--text",
                                            },
                                            [_vm._v(_vm._s(exam.examName))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "f13 ms-1 me-0 green--text",
                                            },
                                            [_vm._v(_vm._s(_vm.$t("View")))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c(
                        "span",
                        { staticClass: "text-center f16 d-block mt-8" },
                        [_vm._v(" " + _vm._s(_vm.$t("No results found")) + " ")]
                      ),
                  _c(
                    "infinite-loading",
                    {
                      attrs: { identifier: _vm.tabs },
                      on: { infinite: _vm.infiniteHandlerAnswered },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "spinner" }, slot: "spinner" },
                        [_c("v-icon", [_vm._v("mdi-selection-ellipse spin")])],
                        1
                      ),
                      _c(
                        "div",
                        { attrs: { slot: "no-more" }, slot: "no-more" },
                        [_c("v-icon", [_vm._v("mdi-access-point-off")])],
                        1
                      ),
                      _c(
                        "div",
                        { attrs: { slot: "no-results" }, slot: "no-results" },
                        [_c("v-icon", [_vm._v("mdi-access-point-off")])],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }