<template>
  <section>
    <v-tabs
      v-model="tabs"
      background-color="grey lighten-4"
      slider-size="0"
      class="rounded-lg"
      grow
    >
      <v-tab active-class="primary white--text rounded-lg">
        <v-badge inline color="primary lighten-1" :content="allCount" :value="allCount">
          {{ $t('All') }}
        </v-badge>
      </v-tab>
      <v-tab active-class="primary white--text rounded-lg">
        <v-badge inline color="primary lighten-1" :content="doneCount" :value="doneCount">
          {{ $t('Done') }}
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <!-------------------ALL--------------------------------------->
      <v-tab-item>
        <v-container fluid>
          <div v-if="allItems && allItems.length > 0">
            <v-card class="my-2" v-for="(exam, index) in allItems" :key="index" flat hover ripple>
              <b-list-group>
                <b-list-group-item class="d-flex align-items-center" @click="handleClickExam(exam, false)">
                  <v-avatar color="primary lighten-5" size="48">
                    <v-img :lazy-src="require('@/assets/images/profile-circle.png')" v-if="exam.profilePicture" :src="exam.profilePicture" />
                    <v-img v-else :src="require('@/assets/images/profile.jpg')" />
                  </v-avatar>
                  <div class="d-flex flex-column ms-2 me-0 align-items-start justify-content-center w-100">
                    <div class="d-flex ms-1 me-0 align-items-center justify-content-between w-100">
                      <span class="f14">{{ exam.firstName }} {{ exam.lastName }}</span>
                      <span class="f12 grey--text">{{exam.createDate | moment("from", "now")}}</span>
                    </div>
                    <span class="f12 ms-1 me-0 grey--text">{{ exam.examName }}</span>

                    <span class="f13 ms-1 me-0 green--text" v-if="exam.status == 'ANSWERED'">{{ $t('Done (View)') }}</span>
                    <span class="f13 ms-1 me-0 green--text" v-else-if="exam.status == 'PAID'">{{ $t('Paid') }}</span>
                    <span class="f13 ms-1 me-0 red--text" v-else-if="exam.status == 'PAYMENT_PENDING'">{{ $t('Not paid') }}</span>
                    <span class="f13 ms-1 me-0 primary--text" v-else-if="exam.status == 'FREE'">{{ $t('Free') }}</span>
                    <span class="f13 ms-1 me-0 grey--text" v-else>{{ $t('Unknown') }}</span>

                  </div>
                </b-list-group-item>
              </b-list-group>
            </v-card>
          </div>

          <span class="text-center f16 d-block mt-8" v-else>
            {{$t('No results found')}}
          </span>

          <infinite-loading @infinite="infiniteHandlerAll" :identifier="tabs">
            <div slot="spinner">
              <v-icon>mdi-selection-ellipse spin</v-icon>
            </div>
            <div slot="no-more">
              <v-icon>mdi-access-point-off</v-icon>
            </div>
            <div slot="no-results">
              <v-icon>mdi-access-point-off</v-icon>
            </div>
          </infinite-loading>
        </v-container>
      </v-tab-item>

      <!-------------------DONE-------------------------------------->
      <v-tab-item>
        <v-container fluid>
          <div v-if="doneItems && doneItems.length > 0">
            <v-card class="my-2 " v-for="(exam, index) in doneItems" :key="index" flat hover ripple>
              <b-list-group>
                <b-list-group-item class="d-flex align-items-center" @click="handleClickExam(exam, true)">
                  <v-avatar color="primary lighten-5" size="48">
                    <v-img :lazy-src="require('@/assets/images/profile-circle.png')" v-if="exam.profilePicture" :src="exam.profilePicture" />
                    <v-img v-else :src="require('@/assets/images/profile.jpg')" />
                  </v-avatar>
                  
                  <div class="d-flex flex-column ms-2 me-0 align-items-start justify-content-center w-100">
                    <div class="d-flex ms-1 me-0 align-items-center justify-content-between w-100">
                      <span class="f14">{{ exam.firstName }} {{ exam.lastName }}</span>
                      <span class="f12 grey--text">{{exam.createDate | moment("from", "now")}}</span>
                    </div>
                    <span class="f12 ms-1 me-0 grey--text">{{ exam.examName }}</span>

                    <span class="f13 ms-1 me-0 green--text">{{ $t('View') }}</span>

                  </div>
                </b-list-group-item>
              </b-list-group>
            </v-card>
          </div>

          <span class="text-center f16 d-block mt-8" v-else>
            {{$t('No results found')}}
          </span>

          <infinite-loading @infinite="infiniteHandlerAnswered" :identifier="tabs">
            <div slot="spinner">
              <v-icon>mdi-selection-ellipse spin</v-icon>
            </div>
            <div slot="no-more">
              <v-icon>mdi-access-point-off</v-icon>
            </div>
            <div slot="no-results">
              <v-icon>mdi-access-point-off</v-icon>
            </div>
          </infinite-loading>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  data() {
    return {
      tabs: null,

      page: 1,
      size: 12,

      allCount: 0,
      doneCount: 0,

      allItems: [],
      doneItems: [],
    }
  },
  methods: {
    handleClickExam(exam, isDone) {
      if(isDone || exam.status == 'ANSWERED') {
        // this.$router.push(`/s/exams/result/${exam.type.toLowerCase()}/${exam.code}`)
        this.$router.push({name: 'DoctorExamResult', params: {obj: {...exam}, examType: exam.type.toLowerCase(), examCode: exam.code}})
      }
    },
    getExamAssignedHistoryAll(page, size){
      apiService.getExamAssignedHistory(page, size, "ALL")
      .then((response) => {
        this.allCount = response.data.totalElements;
        this.allItems = response.data.content;
      })
    },
    getExamAssignedHistoryAnswered(page, size){
      apiService.getExamAssignedHistory(page, size, "ANSWERED")
      .then((response) => {
        this.doneCount = response.data.totalElements;
        this.doneItems = response.data.content;
      })
    },
    infiniteHandlerAll($state) {
      apiService.getExamAssignedHistory(this.page, this.size, "ALL")
        .then((response) => {
          if (response.data.content.length) {
            response.data.content.forEach(element => {
              this.allItems.push(element);
            });

            this.page += 1;
            $state.loaded();
          }
          else {
            $state.complete();
          }
        })
        .catch(() => {
          $state.error();
        });
    },
    infiniteHandlerAnswered($state) {
      apiService.getExamAssignedHistory(this.page, this.size, "ANSWERED")
        .then((response) => {
          if (response.data.content.length) {
            response.data.content.forEach(element => {
              this.doneItems.push(element);
            });

            this.page += 1;
            $state.loaded();
          }
          else {
            $state.complete();
          }
        })
        .catch(() => {
          $state.error();
        });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  mounted(){
    this.getExamAssignedHistoryAll(0, this.size);
    this.getExamAssignedHistoryAnswered(0, this.size);
  },
  watch: {
    tabs: function () {
      this.page = 1;
      if (this.tabs == 0) {
        this.getExamAssignedHistoryAll(0, this.size);
      }
      else {
        this.getExamAssignedHistoryAnswered(0, this.size);
      }
    },
  },
}
</script>
